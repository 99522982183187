import { useEffect, useState, useCallback } from 'react';
import { useApiData } from '../../../contexts/api-data';
import {
    getUsers,
    getWhatsappBotUsers,
} from '../../../services/api';

export function useUsersListPageState() {
    const {
        manageCustomers: {
            fetch: fetchCustomers,
        },
        manageRoles: {
            value: roles,
            fetch: fetchRoles,
        },
    } = useApiData();

    const [filterName, setFilterName] = useState('');
    const [filterActive, setFilterActive] = useState(1);
    const [filterCustomerId, setFilterCustomerId] = useState(0);
    const [sortOptions, setSortOptions] = useState('name=');
    const [users, setUsers] = useState(null);
    const [botUsers, setBotUsers] = useState(null);
    const [shouldFetchBotUser, setShouldFetchBotUser] = useState(true);

    useEffect(() => {
        fetchCustomers();
    }, [fetchCustomers]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    useEffect(() => {
        (async function () {
            const response = await getUsers();
            setUsers(response);
        })();
    }, []);

    useEffect(() => {
        const fetchAllBotUsers = async () => {
            const response = await getWhatsappBotUsers();
            setBotUsers(response);
        }

        if(users?.length > 0 && shouldFetchBotUser){
            fetchAllBotUsers();
        }
    }, [users, shouldFetchBotUser]);

    const findBotUser = useCallback((userId) => {
        return botUsers?.find((botUser) => botUser.cronos_user_id === userId) || {};
      }, [botUsers]);

    const filterCustomerDefaultOption = {
        value: 0,
        label: 'Todos',
    };

    function addNewUser() {
        setShouldFetchBotUser(false);
        const tempId = Date.now();
        setUsers([
            { tempId, inactive: false },
            ...users,
        ]);
    }

    function changeSortOptions(newSortOptions) {
        setSortOptions(newSortOptions);
    }

    function sortUsers(a, b) {
        const [variable, desc] = sortOptions.split('=');
        const [x, y] = [a, b].map((u) => u[variable]);
        return x < y
            ? [-1, 1][Number(Boolean(desc))]
            : (x > y
                ? [1, -1][Number(Boolean(desc))]
                : 0);
    }

    return {
        addNewUser,
        changeSortOptions,
        filterActive,
        filterCustomerDefaultOption,
        filterCustomerId,
        filterName,
        findBotUser,
        roles,
        setFilterActive,
        setFilterCustomerId,
        setFilterName,
        sortOptions,
        sortUsers,
        users,
    };
};
